<template>
    <div class="promise-item-container">
        <h5 class="step-text">{{ step }}</h5>
        <div :class="`promise-item`">
            <div class="content">
                <h4 class="text1">{{ title }}</h4>
                <p class="text2">
                    <slot></slot> 
                </p>
            </div>
        </div>
    </div>
</template>

<style src="./promises.scss" lang="scss" scoped></style>

<script>

export default {
    name: 'PromiseItem',
    components: {  },
    props: ['title', 'step'],
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
