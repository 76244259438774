<template>
    <div class="testimonial-card">
        <div class="testimonial-card-body">
            <div style="margin-bottom: 8px;">
                <font-awesome-icon icon="quote-right" />
            </div>
            <p class="comment" style="height: 200px;overflow: scroll;">
                <slot></slot>
            </p>
            <div class="person">
                <div class="img">
                    {{ breakName(name) }}
                </div>
                <h5 class="name">{{ name }}</h5>
                <star-rating v-bind:read-only="false" :rating="+ratings" :star-size="12" />
            </div>
        </div>
    </div>
</template>

<style src="./Testimonials.scss" lang="scss" scoped></style>

<script>
const StarRating = () => import('vue-star-rating')

export default {
    name: 'TestimonialCard',
    components: {StarRating},
    props: ['comment' ,'ratings','name'],
    data() {
        return {

        }
    },
    methods: {
        breakName(name) {
            const parts = name.trim().split(' ');
            let initials = '';

            if (parts.length === 1) {
                initials = parts[0].substring(0, 2); // First two letters of first name
            } else if (parts.length >= 2) {
                initials = parts[0].charAt(0) + parts[parts.length - 1].charAt(0); // First letter of first name and last name
            }

            return initials.toUpperCase(); // Convert to uppercase for consistency
        }
    }
}
</script>
