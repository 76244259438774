<template>
    <div id="blogs" v-if="posts.length">
        <div class="container">
            <h2 style="text-align: center;margin-bottom: 32px;">Top Cleaning Tips from Our Blog</h2>
            <div class="blog-row-1" style="width: 100%;">
                <div :span="24" :sm="12">
                <blog-item v-if="posts.length" :post="posts[0]" :featured="true" />
                </div>
                <div :span="24" :sm="12">
                    <div class="blog-row-2" style="width: 100%;">
                        <div :span="24" :sm="12">
                            <blog-item v-if="posts.length > 1" :post="posts[1]" />
                        </div>
                        <div :span="24" :sm="12">
                            <blog-item v-if="posts.length > 2" :post="posts[2]" />
                        </div>
                    </div>
                    <div class="blog-row-2" style="width: 100%;">
                        <div :span="24" :sm="12">
                            <blog-item v-if="posts.length > 3" :post="posts[3]" />
                        </div>
                        <div :span="24" :sm="12">
                            <blog-item v-if="posts.length > 4" :post="posts[4]" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="prerendering-content"></div>
    </div>
</template>

<style src="./Blog.scss" lang="scss" scoped></style>

<script>

import GService from "@/api/g-service";
const gService = new GService();
import BlogItem from './BlogItem.vue';

export default {
    name: 'Blogs',
    components: { BlogItem },
    data() {
        return {
            posts: [],
        }
    },
    created() {
        this.getNews()
    },

    methods: {
        async getNews() {
        gService
            .get(`/news-blogs?landing=yes`)
            .then((res) => {
                if (res.status == 'success' && res.data && Array.isArray(res.data.homeNews)) {
                    this.posts = res.data.homeNews
                }
                console.log('news', res)
                // if (res.)
                // if (Array.isArray(res))
            })
            .catch((error) => {
            });
        },
    }
}
</script>
