<template src="./LandingPage.html"></template>

<style src="./LandingPage.scss" lang="scss" scoped></style>

<script>
// import config from '@/config'
// import RequestForOrder from '../RequestForOrder/RequestForOrder.vue';
// import CreateAccountDialog from '../CreateAccountDialog/CreateAccountDialog.vue';
const CreateAccountDialog = () => import('../CreateAccountDialog/CreateAccountDialog.vue')
import Testimonials from '../Testimonials/Testimonials.vue';
import Blogs from '../Blog/Blogs.vue';
import FreshnestSubscriptionItem from '../FreshnestSubscriptions/FreshnestSubscriptionItem.vue';
import HowItWorks from '../how-it-works/how-it-works.vue';
import Promises from '../promise/promises.vue'
import GService from "@/api/g-service";
const gService = new GService();

export default {
  name: "landing",
  metaInfo: {
    title: 'Affordable House Cleaning Service Near Me | The CoBuilders',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.thecobuilders.com'
      }
    ],
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      },
      {
        vmid: 'description',
        name: 'description',
        content: 'Looking for professional house cleaning services near you? Our affordable house cleaning service offers top-quality solutions by experienced professionals.',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'House Cleaning Service, Professional House Cleaning Service, House Cleaning Services Near Me, Professional House Cleaners, Affordable House Cleaning Service'
      },
      {
        name: 'robots',
        content: 'index, follow, max-snippet:-1, max-image-preview: large, max-video-preview:-1'
      },
      {
        property: 'og:title',
        content: 'Affordable House Cleaning Service Near Me | The CoBuilders'
      },
      {
        property: 'og:description',
        content: 'Looking for professional house cleaning services near you? Our affordable house cleaning service offers top-quality solutions by experienced professionals.'
      },
      {
        property: 'og:url',
        content: 'https://www.thecobuilders.com/house-cleaning-services'
      },
      {
        property: 'og:image',
        content: 'https://www.thecobuilders.com/assets/images/cleaning-services.jpg'
      },
      {
        property: 'og:type',
        content: 'website'
      }
    ],
// Organization Schema
script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "The CoBuilders",
            "url": "https://www.thecobuilders.com/",
            "logo": "https://www.thecobuilders.com/app/logo.svg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "214-233-5055",
              "contactType": "customer service"
            },
            "sameAs": [
              "https://www.facebook.com/thecobuilders",
              "https://www.instagram.com/thecobuildersapp/",
              "https://twitter.com/TheCoBuilders",
              "https://www.linkedin.com/company/the-cobuilders/",
              "https://www.youtube.com/channel/UCOOb54mvzHpJNM8-wznojcw"
            ]
          })
        }
      ],
// Local Business Schema
script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "category": "https://schema.org/LocalBusiness",
          "serviceType": "House cleaning service",
          "name": "The CoBuilders",
          "image": "https://www.thecobuilders.com/app/right-image.webp",
          "url": "https://www.thecobuilders.com/",
          "openingHours": "24/7",
          "telephone": "+1 214-233-5055",
          "description": "The CoBuilders is a leading provider of house cleaning services, dedicated to making house cleaning hassle-free and accessible to everyone. With a team of professional and vetted cleaners, The CoBuilders offers a range of cleaning options tailored to meet the diverse needs of homeowners and renters. Whether it's standard cleaning, deep cleaning, move-out cleaning, or before or after event cleaning, The CoBuilders is committed to delivering exceptional service and unmatched convenience to its customers.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "5528 Dunn Hill Drive",
            "addressLocality": "Fort Worth",
            "addressRegion": "TX",
            "postalCode": "76137",
            "addressCountry": "US"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 32.883528,
            "longitude": -97.265528
          },
          "hasMap": "https://g.page/TheCoBuilders?share",
          "sameAs": [
            "https://www.facebook.com/thecobuilders",
            "https://www.instagram.com/thecobuildersapp/",
            "https://twitter.com/TheCoBuilders",
            "https://www.linkedin.com/company/the-cobuilders/",
            "https://www.youtube.com/channel/UCOOb54mvzHpJNM8-wznojcw"
          ] 
        })
      }
    ],
// Breadcrumb Schema
script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Blog",
              "item": "https://www.thecobuilders.com/house-cleaning-tips/blog/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Book House Cleaning",
              "item": "https://www.thecobuilders.com/cleaning-types/book-house-cleaning/"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Contests",
              "item": "https://www.thecobuilders.com/contests/"
            },
            {
              "@type": "ListItem",
              "position": 4,
              "name": "FAQ",
              "item": "https://www.thecobuilders.com/house-cleaning/inquiries/faq/"
            },
            {
              "@type": "ListItem",
              "position": 5,
              "name": "House Cleaners By Cities",
              "item": "https://www.thecobuilders.com/cleaning-services/house-cleaning"
            },
            {
              "@type": "ListItem",
              "position": 6,
              "name": "House Cleaners Nearby",
              "item": "https://www.thecobuilders.com/cleaning-services/house-cleaning/nearme"
            }
          ]
        })
      }
    ]
  },  
components: { CreateAccountDialog, Testimonials, Blogs, FreshnestSubscriptionItem, HowItWorks, Promises },
  data() {
    return {
      openRequestOrderFormDialog: false,
      openCreateAccountFormDialog: false,
      action: null,
      recurringPriceData: null,
    };

  },
  created: function () {
    this.action = this.$router.currentRoute.meta.action;

    if (this.action === 'book-cleaning') {
      this.openRequestOrderFormDialog = true;
    }
  },
  mounted: function() {
    this.getCountries();
    document.dispatchEvent(new Event('app-rendered'));
  },
  methods: {
    redirectTo(url) {
      this.$router.push({ path: url });
    },
    openRequestOrderDialog() {
      this.openRequestOrderFormDialog = true;
    },
    closeRequestOrderFormDialog() {
      this.openRequestOrderFormDialog = false;
    },
    openCreateAccountDialog() {
      this.openCreateAccountFormDialog = true;
    },
    closeCreateAccountDialog(res) {
      this.openCreateAccountFormDialog = false;
    },
    		/**
		 * Get list of countries
		 */
		async getCountries() {
			await gService
				.get(`/registration/get-countries`)
				.then((res) => {
					if (res['status'] === 'success') {
						this.countries = res.data;
						this.countries.forEach((element) => {
              if (element.code === "+1" & element.abbreviation === 'US') {
                this.country_id = element.id;

								if(this.country_id) {
									// this.recurringPriceData = [];
									this.getRecurringPrice(this.country_id);
									} else {
										this.recurringPriceData = {};
									}
              }
            });
					} else {
						this.$message({ message: res['msg'], type: 'error' })
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
    async getRecurringPrice(country_id){
			this.recurringPriceData = [];
			await gService.post(`/recurring-price/by-country-id`,  { country_id: country_id}).then((res) => {
				if (res.status === "success") {
					if(res.data && res.data && res.data.length) {
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index];
              if(element && element.service_type_id && element.service_type_id.code === 'standard') {
                this.recurringPriceData = element;
              }
            }

					}
					
				}
			});
		},
  },
};
</script>
