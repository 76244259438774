<template>
    <div id="how-it-works">
        <div class="container">
            <div style="width: 100%;">
                <h2 class="title">Experience Convenient Cleaning with Our Simple <br /> 3-Step Process</h2>
                <div class="hbox">
                    <div class="hrline" ></div>

                    <how-it-work step="1" title="Effortless Booking" >
                        Easily <a href="https://www.thecobuilders.com/cleaning-types/book-house-cleaning/"><span
                        style="color: skyblue;">book your cleaning</span></a> via phone, secure web form, or The CoBuilders App. Choose your preferences: cleaning type, room details, location, date, and time.
                    </how-it-work>
                    <how-it-work step="2" title="Quick Assignment" >
                        Within 24 hours, we'll pair you with a trusted local cleaner, ensuring reliable service. 
                    </how-it-work>
                    <how-it-work step="3" title="Sit Back and Enjoy" >
                        On your scheduled cleaning day, receive arrival notifications, track progress, and get notified when cleaning is complete. Inspect your sparkling home and revel in the freshness.    
                    </how-it-work>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./how-it-works.scss" lang="scss" scoped></style>

<script>

import HowItWork from './how-it-work.vue';

export default {
    name: 'HowItWorks',
    components: { HowItWork },
    data() {
        return {
            posts: [],
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
