<template>
    <div id="promises">
        <div class="container">
            <div style="width: 100%;">
                <h2 class="title">The CoBuilders Promise</h2>
                <div class="promise-box">
                    <promise-item step="01" title="Transparent Pricing">
                        Clear and upfront pricing.
                    </promise-item>
                    <promise-item step="02" title="Quality Cleaning at Budget-Friendly Prices">
                        Enjoy <a href="https://www.thecobuilders.com/house-cleaning/regular/freshnest-subscriptions/">high-quality cleaning services</a> at affordable rates. Unlimited Savings: Take advantage of coupons, family deals, seasonal offers, and referral credits. 
                    </promise-item>
                    <promise-item step="03" title="Unlimited Savings">
                        Take advantage of coupons, family deals, seasonal offers, and referral credits. 
                    </promise-item>
                    <promise-item step="04" title="Vetted Professionals">
                        Our cleaners undergo thorough criminal background checks for your peace of mind.
                    </promise-item>
                    <promise-item step="05" title="No Contracts">
                        No long-term commitments required. 
                    </promise-item>
                    <promise-item step="06" title="Hassle-Free Cancellation & Refunds">
                        Easy cancellation process and refunds for your convenience. 
                    </promise-item>
                    <promise-item step="07" title="Flexible Scheduling">
                        <a href="https://www.thecobuilders.com/cleaning-types/book-house-cleaning/">Schedule your cleaning</a> according to your preferences.
                    </promise-item>
                    <promise-item step="08" title="Top-Class Customer Support">
                        Reach us anytime via SMS, phone, or email for assistance.
                    </promise-item>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./promises.scss" lang="scss" scoped></style>

<script>

import PromiseItem from './promise-item.vue';

export default {
    name: 'Promises',
    components: { PromiseItem },
    data() {
        return {

        }
    }
}
</script>
