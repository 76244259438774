<template>
    <div id="subscription-item">
        <div :class="`item-box`" @click="navigateToBookCleaning(plan)">
            <div class="item-box2">
                <h4 class="discount">{{ $props.discount }}</h4>
                <p class="interval">{{ $props.interval }}</p>
                <hr>
                <p class="desc">
                    {{ $props.description }}
                </p>
            </div>
            <div v-if="plan != 'ot'" class="triangle-container">
              <div class="triangle"></div>
              <div class="text">{{ $props.bottomText }}</div>
            </div>
            <!-- <p class="bottom-text">{{ $props.bottomText }}</p> -->
        </div>
    </div>
</template>

<style src="./FreshnestSubscriptionItem.scss" lang="scss" scoped></style>

<script>

export default {
  name: "FreshNestSubscriptionItem",
  components: {  },
  props: ['discount', 'interval', 'description', 'bottomText', 'plan'],
  data() {
    return {}
  },
  methods: {
    navigateToBookCleaning(plan) {
      this.$router.push({ path: '/cleaning-types/book-house-cleaning/', query: {s: plan} });
    },
  }
};
</script>
