<template>
    <div :class="`how-it-work step-${step}`">
        <div :class="`hstep`">{{ step }}</div>
        <h4 class="how-title">{{ title }}</h4>
        <p class="how-text">
            <slot></slot>
        </p>
    </div>
</template>

<style src="./how-it-works.scss" lang="scss" scoped></style>

<script>

export default {
    name: 'HowItWork',
    components: {  },
    props: ['step', 'title'],
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
