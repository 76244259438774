import { render, staticRenderFns } from "./how-it-works.vue?vue&type=template&id=b4a42564&scoped=true"
import script from "./how-it-works.vue?vue&type=script&lang=js"
export * from "./how-it-works.vue?vue&type=script&lang=js"
import style0 from "./how-it-works.scss?vue&type=style&index=0&id=b4a42564&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a42564",
  null
  
)

export default component.exports