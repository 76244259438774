<template>
    <div id="blog-item" @click="handleClick(post.url_id)">
        <div :class="$props.featured ? `featured-img-box`: `img-box`">
            <img v-lazy="post.featured_image" />
        </div>
        <p class="heading">{{ post.headline }}</p>
        <div class="blog-footer">
            <p> <span class="el-icon-date"></span>&nbsp;{{ post.published_at }}</p>
            <p> <span class="el-icon-view"></span>&nbsp;{{ post.read_count }}</p>
            <p> <span class="el-icon-reading"></span>&nbsp;{{ post.readingTime + ` min` }}</p>
        </div>
        <p class="read-article">read article</p>
    </div>
</template>

<style src="./Blog.scss" lang="scss" scoped></style>

<script>
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

export default {
    name: 'BlogItem',
    components: {},
    props: ['featured', 'post'],
    data() {
        return {

        }
    },
    created() {
    },
    methods: {
        handleClick(url) {
            this.$router.push({ path: `/house-cleaning-tips/blog/blog-details/${url}` });
        }
    }
}
</script>
