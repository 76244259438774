import request from './request';
import axios from 'axios';
import appConfig from '@/config/config';

/**
 * Simple RESTful resource class
 */
class GService {
  // constructor() {
  // }
  get(url, params) {
    return request({ url: url, method: 'get', params: params });
  }

  put(url, data, params) {
    return request({ url: url, method: 'put', data: data, params: params });
  }

  post(url, data, params, cancelToken) {
    return request({ url: url, method: 'post', data: data, params: params, cancelToken: cancelToken });
  }

  delete(url, params) {
    return request({ url: url, method: 'delete', params });
  }

  download(url) {
    return axios.get(
      process.env.VUE_APP_BASE_API_URL + '/app/' + url,
      { headers: { 'Authorization': 'bearer ' + localStorage.getItem(appConfig.cookie.bearerName) }, responseType: 'arraybuffer' }
    );
  }

  // TODO:medium: handle content type (multipart/form-data) header in request.js
  postWithFile(url, formData) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axios.post(`${process.env.VUE_APP_BASE_API_URL}/${url}`, formData, { headers });
  }
  // TODO:medium: handle content type (multipart/form-data) header in request.js
  putWithFile(url, formData) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axios.put(`${process.env.VUE_APP_BASE_API_URL}/${url}`, formData, { headers });
  }

}

export { GService as default };
