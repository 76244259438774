import axios from 'axios';
import appConfig from '@/config/config';
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// TODO:high:critical VUE_APP_BASE_API_URL should come from env but somehow vue is not working fine with .env.stage it is only recognizing dev on staging as well.
// I have spent about 2 hours finding out this fu***ng problem but no success.
// let VUE_APP_BASE_API_URL = '';
// if (process.env.NODE_ENV === 'production') {
//   VUE_APP_BASE_API_URL = 'https://api.thecobuilders.com';
// } else if (process.env.NODE_ENV === 'development') {
//   VUE_APP_BASE_API_URL = 'http://localhost:1338';
// } else if (process.env.NODE_ENV === 'stage') {
//   VUE_APP_BASE_API_URL = 'https://v2api-stage.thecobuilders.com';
// }

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API_URL,
  baseURL: process.env.VUE_APP_BASE_API_URL + '/app',
  timeout: 60000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (localStorage.getItem(appConfig.cookie.bearerName)) {
      config.headers['Authorization'] = 'bearer ' + localStorage.getItem(appConfig.cookie.bearerName);
      // config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    return res;
    // if (res.status && res.status === 'error') {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    console.log('err' + error); // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;
