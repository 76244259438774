<template>
    <div id="testimonials">
      <div class="container">
        <div style="width: 100%;">
          <h2 style="text-align: center;margin-bottom: 64px;">Hear What Our Satisfied Customers Have to Say</h2>
          <carousel :perPage="1" :loop="true" :autoplay="true" :perPageCustom="[[768, 3]]">
            <slide>
              <TestimonialCard name="JoAnn" ratings="5">
                Tish did a fantastic job and I would like to schedule her regularly.
              </TestimonialCard>
            </slide>
            <slide>
              <TestimonialCard name="Vivian" ratings="4">
                I had my first experience with a cleaning service I was impressed with my cleaner she needs more experience but what she did for me I was so relieved that I couldn't wait for the next time
                Thank you co.builders you are awesome 🧡🏈🏀 from a 💯🧡🇺🇸 Tennessee USA Vol Fan 🧡💯🧡🏈🏀
              </TestimonialCard>
            </slide>
            <slide>
              <TestimonialCard name="Marcie" ratings="5" >
                I just want to give a shoutout. My cleaning today was unbelievable. The cleaner was so kind. I’m disabled, and I now have this sweet cloud over my house. Oh my gosh! She did my dishes. What? She did everything, she was awesome, and I just want to thank you so much.
              </TestimonialCard>
            </slide>
            <slide>
              <TestimonialCard name="Oluwatosin" ratings="5" >
                Great and smooth experience.
              </TestimonialCard>
            </slide>
            <slide>
              <TestimonialCard name="Faye" ratings="5" >
                The CoBuilders app makes it easy for customers to get detailed cleaning service price estimates. If you want to predict pricing for standard cleaning, deep cleaning , or move out cleaning, all you have to do is check out the app. You can provide them with your location, desired time and cleaning service, that's all there is to it. Also, the cleaning services are reliable, efficient and affordable.
              </TestimonialCard>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
</template>

<style src="./Testimonials.scss" lang="scss" scoped></style>

<script>
import { Carousel, Slide } from 'vue-carousel';
import TestimonialCard from './TestimonialCard.vue';

export default {
    name: 'Testimonials',
    components: {Carousel, Slide, TestimonialCard},
    data() {
        return {

        }
    },
}
</script>
